@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap);
:root {
  --bcg-color: #f4f8f8;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'IMB Plex Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  background-color: #f4f8f8 !important;
  background-color: var(
    --bcg-color
  ) !important; /* this is temporary until I figure out MUI theme background */
}

.leaflet-popup {
  min-width: 300px;
}

.leaflet-popup .leaflet-popup-content {
  margin: 0;
}

.leaflet-container .leaflet-popup a.leaflet-popup-close-button {
  width: unset;
  height: unset;
  top: 6px;
  right: 6px;
  padding: 8px;
}
